@tailwind base;
@tailwind components;
@tailwind utilities;
.marker {
  background-image: url("images/mapbox-marker-icon-20px-blue.png");
  width: 25px;
  height: 50px;
  background-size: cover;
  cursor: pointer;
}

.mapboxgl-popup-content {
  text-align: center;
  font-family: "Open Sans", sans-serif;
}
